import { likeRegExp } from '@/offline/database'

export default {
  _selectSyncUploadBase (Vue) {
    const tables = Vue.$offline.db.tables
    const syncUploadRes = Vue.$offline.syncUpload
    return Vue.$offline.db
      .select()
      .from(tables.sync_upload)
      .innerJoin(syncUploadRes.dummyTable, syncUploadRes.pk.eq(syncUploadRes.dummyPk))
      .orderBy(tables.sync_upload.timestamp, Vue.$offline.db.order.DESC)
  },
  async selectSyncUpload (Vue, filter, search, sorter, page) {
    const tables = Vue.$offline.db.tables
    let where = []
    let whereSearch = []
    let whereFilter = []
    if (search) {
      const reQ = likeRegExp(search)
      whereSearch.push(
        Vue.$offline.db.op.or(
          tables.sync_upload.idsync_upload.eq(search),
          tables.sync_upload.errors.match(reQ),
        )
      )
    }
    where.push(
      Vue.$offline.db.op.and(
        tables.sync_upload.synced.eq(true),
        ...whereSearch,
        ...whereFilter
      )
    )
    return [
      await this._selectSyncUploadBase(Vue)
        .where(...where)
        .limit(Vue.$offline.db.ITEMS_PER_PAGE)
        .skip(page * Vue.$offline.db.ITEMS_PER_PAGE)
        .exec(),
      []
    ]
  },
  selectSyncUploadRows (Vue, pks) {
    const tables = Vue.$offline.db.tables
    return this._selectSyncUploadBase(Vue)
      .where(tables.sync_upload.idsync_upload.in(pks))
      .exec()
  }
}
