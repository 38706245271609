<template>
  <b10-list-item
    :item="formattedItem"
  >
    <v-list-item-avatar>
      <div
        class="text-xs-center"
      >
        <v-avatar
          :color="formattedItem.avatarColor"
        >
          <span class="white--text font-weight-bold subtitle-1">{{ formattedItem.avatar }}</span>
        </v-avatar>
      </div>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title
        v-html="formattedItem.title"
      />
      <v-list-item-subtitle
        v-html="formattedItem.subtitle"
      />
    </v-list-item-content>
  </b10-list-item>
</template>

<script>
import _ from '@/utils/lodash'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    formattedItem () {
      const item = _.cloneDeep(this.item)
      item.title = `Nº${item.sync_upload.idsync_upload} - ${this.$options.filters.shortDateTime(item.sync_upload.timestamp)}`
      item.subtitle = `Correctos ${item.sync_upload.count_ok}, con error ${item.sync_upload.count_errors}`
      if (item.sync_upload.count_errors > 0) {
        item.avatar = 'ERR'
        item.avatarColor = 'error'
      } else {
        item.avatar = 'OK'
        item.avatarColor = 'success'
      }
      return item
    }
  }
}
</script>
